@layer utilities_display {
  .dn { display: none; }
  .di { display: inline; }
  .db { display: block; }
  .dib { display: inline-block; }
  .dif { display: inline-flex; }
  .flex { display: flex; }
  .vis-hide { visibility: hidden; }

  .display-none { display: none; }
  .display-inline { display: inline; }
  .display-block { display: block; }
  .display-inlineblock { display: inline-block; }
  .display-inlineflex { display: inline-flex; }
  .display-flex { display: flex; }
  .visibility-hidden { visibility: hidden; }


  /* Screen reader text
   http://v4-alpha.getbootstrap.com/components/utilities/#screen-readers-and-keyboard-users */

  .screen-reader-text {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .screen-reader-text--focusable:active,
  .screen-reader-text--focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }

  input.hidden {
    opacity: 0;
    position: fixed;
    width: 0px;
  }
}
