/* Flex */
@layer utilities_flexbox_flex {
  .flex-none {
      flex: none;
  }

  .flex-auto {
      flex: 1 1 auto;
  }

  .flex-1 {
      flex: 1 1 0%;
  }
}
