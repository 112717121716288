@layer utilities_style_border {
  .mb-border {
    border-width: 2px;
    border-color: var(--gray-80);
  }

  .ba {
    border-style: solid;
  }
  .bt {
    border-top-style: solid;
  }
  .br {
    border-right-style: solid;
  }
  .bb {
    border-bottom-style: solid;
  }
  .bl {
    border-left-style: solid;
  }
  .bn {
    border-style: none;
    border-width: 0;
  }

  .b--white {
    border-color: #fff;
  }

  .bw0 {
    border-width: 0;
  }
}

@layer utilities_style_rounded-corners {
  .rad-a2px {
    border-radius: 2px;
  }

  .rad-tl2px {
    border-top-left-radius: 2px;
  }

  .rad-tr2px {
    border-top-right-radius: 2px;
  }

  .rad-br2px {
    border-bottom-right-radius: 2px;
  }

  .rad-bl2px {
    border-bottom-left-radius: 2px;
  }

  .rad-t2px {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .rad-b2px {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}
