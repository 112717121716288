@layer components_pagination {
  .pagination {
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    gap: 4px;

    & li {
      text-align: center;
    }

    [aria-hidden='true'] {
      font-size: 0.95rem;
      min-width: 2em;

      > * {
        display: none;
      }
    }

    & button,
    & a {
      color: black;
      display: flex;
      background-color: transparent;
      padding: 0.25em 0.5em 0.125em 0.5em;
      border: 0;
      border-bottom: 2px solid transparent;
      line-height: 1.5;
      text-decoration: none;
      transition: var(--easeout-15);
      font-weight: 500;
      font-size: 0.95rem;
      min-width: 2em;
      min-height: 2em;
      justify-content: center;
      align-items: center;

      &:focus-visible,
      &:active,
      &:hover {
        background-color: var(--gray-95);
        border-radius: 2px;

        & svg {
          color: black;
        }
      }

      & svg {
        color: var(--gray-40);
        width: 1em;
        height: 1em;
      }

      &[aria-current='page'] {
        border-bottom: 2px solid var(--green);
        color: var(--green-dark);
      }
    }
  }
}
