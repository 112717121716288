@layer components_badges {
  .mb-badge {
    background-color: var(--gray-95);
    padding: 0.1em 0.25em;
    color: var(--gray-35);
    font-size: 0.9em;
    border-radius: 2px;
  }

  .mb-badge--green {
    background-color: var(--green-lighter);
    color: var(--green-darker);
  }

  .mb-badge--blue {
    background-color: hsl(212, 78%, 95%);
    color: hsl(212, 78%, 27%);
  }

  .mb-badge--yellow {
    background-color: hsl(48, 78%, 93%);
    color: hsl(39, 78%, 27%);
  }

  .mb-badge--red {
    background-color: hsl(3, 27.5%, 95%);
    color: hsl(0, 37.5%, 35%);
  }
}
