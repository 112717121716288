:root {
  /* gray */
  --gray-98: hsl(0, 0%, 98%); /* #fafafa */
  --gray-95: hsl(0, 0%, 95%); /* #f2f2f2 */
  --gray-93: hsl(0, 0%, 93.5%); /* #eeeeee */
  --gray-90: hsl(0, 0%, 90%);
  --gray-86: hsl(0, 0%, 86%);
  --gray-80: hsl(0, 0%, 80%); /* #cccccc */
  --gray-75: hsl(0, 0%, 75%);
  --gray-66: hsl(0, 0%, 66.5%); /* #aaaaaa */
  --gray-60: hsl(0, 0%, 60%); /* #999999 */
  --gray-55: hsl(0, 0%, 55%);
  --gray-40: hsl(0, 0%, 40%); /* #666666 */
  --gray-35: hsl(0, 0%, 35%);
  --gray-20: hsl(0, 0%, 20%); /* #333333 */

  /* green */
  --green: hsl(101, 63%, 44%); /* #56b529 */
  --green-hover: hsl(101, 55%, 53%); /* #6ec943 */
  --green-active: hsl(101, 63%, 40%);

  --green-light: hsl(96, 53%, 74%); /* #b5e099 */
  --green-light-hover: hsl(96, 53%, 82%);

  --green-lighter: hsl(86, 47%, 94%); /* #f1f7e9 */
  --green-lighter-hover: hsl(86, 40%, 91%);
  --green-lighter-active: hsl(86, 44%, 89%);

  --green-dark: hsl(155, 100%, 19.6%); /* #00643a */
  --green-dark-hover: hsl(155, 100%, 24.6%);

  --green-darker: hsl(152, 100%, 9%);

  /* red */
  --red: hsl(3, 77.5%, 51.5%); /* #e32d22 */
  --red-light: hsl(3, 77.5%, 65%);
  --red-lighter: hsl(3, 77.5%, 96%);
  --red-dark: hsl(0, 100%, 30%); /* #980000 */

  /* yellow */
  --yellow: hsl(41, 98%, 58.5%); /* #fdbb2f */
  --yellow-light: hsl(41, 98%, 65%);
  --yellow-lighter: hsl(48, 98%, 92%);
  --yellow-dark: hsl(39, 100%, 50%); /* #ffa400 */

  /* blue */
  --blue-link: hsl(210, 100%, 40%);

  --font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Ubuntu, 'Helvetica Neue', Arial, sans-serif;

  --shadow: 0 2px 2px 0 rgb(0 0 0 / 6%), 0 0px 5px 0 rgb(0 0 0 / 8%);
  --shadow-dark: 0 2px 2px 0 rgb(0 0 0 / 10%), 0 1px 5px 0 rgb(0 0 0 / 12%);

  --easeout-15: 0.15s ease-out;
}
