@layer utilities_size-and-space_width {
  /* width */

  .w4r {
    width: 4rem;
  }
  .w5r {
    width: 5rem;
  }
  .w6r {
    width: 6rem;
  }
  .w8r {
    width: 8rem;
  }
  .w10r {
    width: 10rem;
  }
  .w12r {
    width: 12rem;
  }
  .w16r {
    width: 16rem;
  }

  .wauto {
    width: auto;
  }

  .w100p {
    width: 100%;
  }

  /* width screens above 62.5em */

  @media screen and (min-width: 62.5em) {
    .wauto-l {
      width: auto;
    }

    .w5p-l {
      width: 5%;
    }
    .w10p-l {
      width: 10%;
    }
    .w15p-l {
      width: 15%;
    }
    .w20p-l {
      width: 20%;
    }
    .w25p-l {
      width: 25%;
    }
    .w30p-l {
      width: 30%;
    }
    .w33p-l {
      width: 33.3%;
    }
    .w40p-l {
      width: 40%;
    }
    .w50p-l {
      width: 50%;
    }
    .w60p-l {
      width: 60%;
    }
    .w66p-l {
      width: 66.7%;
    }
    .w70p-l {
      width: 70%;
    }
    .w75p-l {
      width: 75%;
    }
    .w80p-l {
      width: 80%;
    }
    .w85p-l {
      width: 85%;
    }
    .w90p-l {
      width: 90%;
    }
    .w100p-l {
      width: 100%;
    }
  }

  /* max-width */

  .maxw8r {
    max-width: 8rem;
  }
  .maxw10r {
    max-width: 10rem;
  }
  .maxw12r {
    max-width: 12rem;
  }
  .maxw16r {
    max-width: 16rem;
  }
  .maxw20r {
    max-width: 20rem;
  }
  .maxw24r {
    max-width: 24rem;
  }
  .maxw32r {
    max-width: 32rem;
  }
  .maxw40r {
    max-width: 40rem;
  }
  .maxw44r {
    max-width: 44rem;
  }
  .maxw48r {
    max-width: 48rem;
  }
  .maxw64r {
    max-width: 64rem;
  }
  .maxw80r {
    max-width: 80rem;
  }
  .maxw96r {
    max-width: 96rem;
  }

  .maxw100vw {
    max-width: 100vw;
  }

  .maxw100p {
    max-width: 100%;
  }

  .maxwmaxc {
    max-width: max-content;
  }

  /* min-width */

  .minw5r {
    min-width: 5rem;
  }
  .minw6r {
    min-width: 6rem;
  }
  .minw7r {
    min-width: 7rem;
  }
  .minw8r {
    min-width: 8rem;
  }
  .minw9r {
    min-width: 9rem;
  }
  .minw10r {
    min-width: 10rem;
  }
  .minw12r {
    min-width: 12rem;
  }
}
