@layer components_tables {
  .mb-table,
  .mb-docs table {
    line-height: 1.2;
    border-collapse: collapse;
    font-size: clamp(0.875rem, 0.8rem + 0.15vw, 1rem);

    caption {
      margin-bottom: 0.5em;
    }

    tr {
      border-bottom: 1px solid var(--gray-80);
    }

    thead tr:last-of-type {
      border-bottom-width: 2px;
    }

    tfoot tr {
      &:first-of-type {
        border-top: 2px solid var(--gray-80);
      }

      &:last-of-type {
        border-bottom-width: 2px;
      }
    }

    th,
    td {
      padding: 0.8em 1.6em 0.7em 0.4em;

      &:has(.icon-ui) {
        padding-bottom: 0.6em;
      }

      &:has(.icon-ui--m) {
        padding-top: 0.6em;
        padding-bottom: 0.3em;
      }

      &:has(.btn-link, .btn-link--dark, button) {
        padding-top: 0.5em;
        padding-bottom: 0.4em;
      }
    }

    thead th:last-of-type,
    td:last-of-type {
      padding-right: 0.4em;
    }

    .icon-ui {
      width: 1em;
      height: 1em;
    }

    .icon-ui--m {
      width: 1.5em;
      height: 1.5em;
    }

    /* nested tables */
    table {
      font-size: 0.95em;
      width: 100%;
      margin-bottom: 2em;
    }

    td:has(> table, .mb-table) {
      background-color: var(--gray-95);
      padding: 1em 2em 0;

      & & {
        background-color: white;
      }
    }
  }
}

/* sticky headers */
@layer components_tables_overflow {
  .mb-table__sticky-top thead,
  .mb-table__sticky-left thead th:first-of-type,
  .mb-table__sticky-left tbody th,
  .mb-table__sticky-left tfoot th:first-of-type {
    background: hsl(0, 0%, 100%);
    position: sticky;
    background-clip: padding-box;
  }

  .mb-table__sticky-top thead {
    top: 0;
    z-index: 1;
  }

  .mb-table__sticky-left thead th:first-of-type,
  .mb-table__sticky-left tbody th,
  .mb-table__sticky-left tfoot th:first-of-type {
    left: 0;
  }
}

/* sorting */
@layer components_tables_sorting {
  .mb-table__sort {
    transition: var(--easeout-15);
    cursor: default;
  }

  .mb-table__sort:hover,
  .mb-table__sort:focus-visible {
    text-decoration: none;
    background-color: var(--gray-93);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    cursor: initial;
  }

  .mb-table__sort svg:not(button *) {
    transition: var(--easeout-15);
    fill: currentColor;
    color: var(--gray-66);
    margin-left: 0.4em;
    margin-bottom: -0.05em;
    width: 1em;
    height: 1em;
  }

  .mb-table__sort:hover svg:not(button *),
  .mb-table__sort:focus-visible svg:not(button *) {
    color: var(--gray-40);
  }

  .mb-table__sort--by svg:not(button *) {
    color: var(--gray-40);
  }
}

/* row state */
@layer components_tables_state-and-action {
  .mb-table__row--clickable:hover,
  .mb-table__row--clickable:focus-visible {
    background-color: var(--gray-95);
  }

  .mb-table__row--selected {
    background-color: var(--green-lighter-active);
  }

  .mb-table__row--inactive {
    background-color: hsl(48, 58%, 96%);
    color: var(--gray-40);
  }
}

/* vertical shade for horizontal scrolling */
@layer components_tables_overflow {
  .scrollshade-h {
    background-image: linear-gradient(
        to right,
        hsl(0, 0%, 100%) 0.25rem,
        hsla(0, 0%, 100%, 0)
      ),
      linear-gradient(to right, hsla(0, 0%, 100%, 0), hsl(0, 0%, 100%)),
      linear-gradient(to right, hsla(0, 0%, 75%, 0.5), hsla(0, 0%, 100%, 0)),
      linear-gradient(to left, hsla(0, 0%, 75%, 0.5), hsla(0, 0%, 100%, 0));
    background-position:
      0 center,
      100.1% center,
      0 center,
      100% center;
    background-repeat: no-repeat;
    background-color: hsl(0, 0%, 100%);
    background-size:
      20% 100%,
      20% 100%,
      0.25rem 100%,
      0.25rem 100%;
    background-attachment: local, local, scroll, scroll;
  }

  /* 
      Controlling overflow
  */
  .of-auto {
    overflow: auto;
  }
  .ofx-auto {
    overflow-x: auto;
  }
  .ofy-auto {
    overflow-y: auto;
  }
}
