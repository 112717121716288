@layer components_notice {
  .mb-notice {
    background-color: #fff;
    box-shadow: var(--shadow);
    padding: 1rem;

    svg {
      fill: var(--green-dark);
      flex: 0 0 1.25em;
      height: 1.25em;
      margin-top: 0.3em;
    }
  }

  .mb-notice :last-child {
    margin-bottom: 0rem;
  }

  .mb-notice div:last-child :last-child {
    margin-bottom: 0rem;
  }

  .mb-notice.mb-notice--green {
    background-color: var(--green-dark);
    color: #fff;
    box-shadow: none;

    a {
      color: var(--green-light);
    }

    a:hover,
    a:focus-visible {
      color: var(--gray-90);
    }

    svg {
      fill: var(--green-light);
    }

    .btn,
    .btn:link,
    .btn:visited {
      border: 1px solid hsl(101, 68%, 34%);
      background-color: var(--green);
    }

    .btn:hover,
    .btn:focus-visible {
      border: 1px solid hsl(101, 68%, 34%);
      background-color: var(--green-hover);
    }

    .btn:active,
    .btn.active {
      border: 1px solid hsl(101, 68%, 34%);
      background-color: var(--green-active);
    }

    .btn:disabled {
      border: 1px solid hsl(86, 34%, 85%);
      background-color: var(--green-lighter);
    }
  }
}