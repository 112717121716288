@layer components_alerts {
  .message--success {
    background-color: var(--green-lighter);
    fill: var(--green);
    border-left: 4px solid var(--green);
    border-radius: 2px;
  }

  .message--warn {
    background-color: var(--yellow-lighter);
    fill: var(--yellow-light);
    border-left: 4px solid var(--yellow);
    border-radius: 2px;
  }

  .message--error {
    background-color: var(--red-lighter);
    fill: var(--red-light);
    border-left: 4px solid var(--red);
    border-radius: 2px;
  }
}

@layer components_help {
  .message--info {
    background-color: var(--gray-95);
    fill: var(--gray-55);
    border-radius: 2px;
  }
}
