/*
    Base – Unclassed HTML elements.
*/
@layer base {
  html {
    background-color: #fff;
    color: var(--gray-20);
    overflow-y: scroll;
    min-height: 100%;
    font-family: var(--font-family);
  }

  html,
  details * {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    font-size: 100%;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  a {
    color: var(--blue-link);
  }

  a:hover,
  a:focus-visible {
    color: var(--gray-20);
    text-decoration-color: var(--gray-60);
  }

  :is(nav, header) {
    & a {
      text-decoration: none;
    }
  }

  code {
    font-family:
      Consolas,
      SF Mono,
      Menlo,
      'Andale Mono',
      'Ubuntu Mono',
      'Courier New',
      Courier,
      monospace;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.2rem;
    font-weight: 600;
  }

  h1 {
    font-size: 2rem;
    line-height: 1.25;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.875rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol,
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  dt {
    font-weight: 600;
  }

  dd {
    margin-left: 0;
  }

  caption {
    text-align: left;
    font-size: 1.25rem;
  }

  thead {
    vertical-align: bottom;
  }

  tbody,
  tfoot {
    vertical-align: top;
  }

  tfoot {
    font-weight: 600;
  }

  th {
    text-align: left;
    font-weight: 600;
  }

  form {
    margin: 0;
    padding: 0;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    padding: 0 0 0.25em;
    font-weight: 600;
  }

  label {
    display: inline-block;
    margin-bottom: 0.3rem;
  }

  figure {
    margin: 0;
  }

  figcaption {
    font-size: 0.875rem;
    margin: 1em 0;
    font-style: italic;
  }

  /* form elements tend to not inherit various font stuff */
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    line-height: inherit;
    border-radius: 0;
    font-size: 100%;
  }

  input[type='search'] {
    box-sizing: inherit;
    -webkit-appearance: none;
  }

  hr {
    border: none;
    border-bottom: 2px solid var(--gray-86);
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  sub,
  sup {
    line-height: 1;
  }

  /* Remove the inheritance of text transform in Firefox. */

  button,
  select {
    /* 1 */
    text-transform: none;
  }

  /* Correct the inability to style clickable types in iOS and Safari. */

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }
}
