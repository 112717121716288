.justify-between {
  --deprecated: 'WARNING use "justify-csb" instead';
  justify-content: space-between;
}

/* Deprecated text sizing classes from 2016–2018 adjusted to 2024 scale. */
.f2 {
  --deprecated: 'WARNING use "text-2r" instead';
  font-size: 2rem;
}
.f3,
.text-title {
  --deprecated: 'WARNING use "text-1.5r" instead';
  font-size: 1.5rem;
}
.f4,
.text-heading {
  --deprecated: 'WARNING use "text-1.25r" instead';
  font-size: 1.25rem;
}
.f5,
.text-basic {
  --deprecated: 'WARNING use "text-1r" instead';
  font-size: 1rem;
}
.f6,
.text-note {
  --deprecated: 'WARNING use "text-0.875r" instead';
  font-size: 0.875rem;
}

/* Deprecated tables. */
.table {
  --deprecated: 'WARNING use "mb-table" instead';
  line-height: 1.2;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.table th,
.table td {
  --deprecated: 'WARNING use "mb-table" instead';
  padding: 0.5em 1em;
}

.table__thead {
  --deprecated: 'WARNING use "mb-table" instead';
  border-bottom: 2px solid #ccc;
}

.table--lined tbody tr {
  --deprecated: 'WARNING use "mb-table" instead';
  border-top: 1px solid #ddd;
}

.table--hover tbody tr:hover {
  --deprecated: 'WARNING use "mb-table__row--clickable" instead';
  background-color: #f2f2f2;
}

.table__thead--dark {
  --deprecated: 'WARNING EOL - no alternatives';
  background-color: #666;
  border-bottom: 1px solid #666;
  color: #fff;
}

.mb-table__sort svg {
  --deprecated: 'WARNING use "sort" on svg instead';
  transition: color 0.2s;
  fill: currentColor;
  color: var(--gray-66);
  margin-left: 0.4em;
  margin-bottom: -0.05em;
  width: 1em;
  height: 1em;
}

.mb-table__sort:hover svg,
.mb-table__sort:focus-visible svg {
  --deprecated: 'WARNING use "sort" on svg instead';
  color: var(--gray-40);
}

.mb-table__sort--by svg {
  --deprecated: 'WARNING use "sort" svg instead';
  color: var(--gray-40);
}

/* Old width classes and percentages */
.w3 {
  --deprecated: 'WARNING use "w4r" instead';
  width: 4rem;
}
.w4 {
  --deprecated: 'WARNING use "w8r" instead';
  width: 8rem;
}
.w5 {
  --deprecated: 'WARNING use "w16r" instead';
  width: 16rem;
}

.w5p,
.w-5 {
  --deprecated: 'WARNING - consider rem and flex instead';
  width: 5%;
}
.w10p,
.w-10 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 10%;
}
.w15p,
.w-15 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 15%;
}
.w20p,
.w-20 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 20%;
}
.w25p,
.w-25 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 25%;
}
.w30p,
.w-30 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 30%;
}
.w33p {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 33%;
}
.w40p,
.w-40 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 40%;
}
.w50p,
.w-50 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 50%;
}
.w60p,
.w-60 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 60%;
}
.w66p,
.w-66 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 66.7%;
}
.w70p,
.w-70 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 70%;
}
.w-75 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 75%;
}
.w80p,
.w-80 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 80%;
}
.w85p,
.w-85 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 85%;
}
.w-90 {
  --deprecated: 'WARNING consider rem and flex instead';
  width: 90%;
}
.w-100 {
  --deprecated: 'WARNING use "w100p" or consider rem and flex';
  width: 100%;
}
.w-auto {
  --deprecated: 'WARNING use "wauto" instead';
  width: auto;
}

@media screen and (min-width: 62.5em) {
  .w-5-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 5%;
  }
  .w-10-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 10%;
  }
  .w-15-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 15%;
  }
  .w20p-l,
  .w-20-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 20%;
  }
  .w-25-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 25%;
  }
  .w-30-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 30%;
  }
  .w-33-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 33.3%;
  }
  .w40p-l,
  .w-40-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 40%;
  }
  .w50p-l,
  .w-50-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 50%;
  }
  .w-60-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 60%;
  }
  .w-66-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 66.7%;
  }
  .w-70-l {
    --deprecated: 'WARNING consider rem and flex instead';
    width: 70%;
  }
  .w-100-l {
    --deprecated: 'WARNING use "w100p-l" or consider flex';
    width: 100%;
  }
  .w-auto-l {
    --deprecated: 'WARNING use "wauto-l" or consider flex';
    width: auto;
  }
}

.mw-100 {
  --deprecated: 'WARNING use "maxw100p" instead';
  max-width: 100%;
}
.mw4 {
  --deprecated: 'WARNING use "maxw8r" instead';
  max-width: 8rem;
}
.mw6 {
  --deprecated: 'WARNING use "maxw32r" instead';
  max-width: 32rem;
}
.mw7 {
  --deprecated: 'WARNING use "maxw48r" instead';
  max-width: 48rem;
}
.mw8 {
  --deprecated: 'WARNING use "maxw64r" instead';
  max-width: 64rem;
}

/* Deprecated form elements and buttons */
.form__control--success {
  --deprecated: 'WARNING EOL - no alternatives';
  border: 1px solid var(--green);
}

.form__control--disabled {
  --deprecated: 'WARNING handled by "form__control:disabled" instead';
  border: 1px solid var(--gray-86);
  background-color: var(--gray-98);
  color: var(--gray-40);
}

input.form__control--disabled {
  --deprecated: 'WARNING handled by "form__control:disabled" instead';
  color: var(--gray-60);
}

.btn-link__icon {
  --deprecated: 'WARNING use "mrxs" instead';
  margin-right: 0.25em;
}

/* deprecated colours and backgrounds */
.bg-gray1 {
  --deprecated: 'WARNING EOL - no alternatives';
  background-color: var(--gray-98);
}

.bg-gray3 {
  --deprecated: 'WARNING EOL - no alternatives';
  background-color: var(--gray-93);
}

.bg-gray4 {
  --deprecated: 'WARNING EOL - no alternatives';
  background-color: var(--gray-86);
}

.bg-green1 {
  --deprecated: 'WARNING - no alternatives';
  background-color: var(--green-lighter);
}

.near-black {
  --deprecated: 'WARNING use "base" instead';
  color: var(--gray-20);
}

.gray {
  --deprecated: 'WARNING use "base" instead';
  color: var(--gray-20);
}

.message-icon {
  --deprecated: 'WARNING EOL - don’t have icons in alerts';
  flex: 0 0 1.25rem;
  height: 1.25rem;
  width: 1.25rem;
}

/* deprecated borders */
.mybr-border--light,
.mybr-border {
  --deprecated: 'WARNING use "mb-border" instead';
  border-width: 2px;
  border-color: var(--gray-80);
}

.b--gray2,
.b--gray3,
.b--gray4,
.b--gray5,
.b--gray6 {
  --deprecated: 'WARNING EOL use "mb-border" instead';
  border-color: var(--gray-80);
}

.b--green {
  --deprecated: 'WARNING EOL - no alternatives';
  border-color: var(--green);
}
.b--red {
  --deprecated: 'WARNING EOL - no alternatives';
  border-color: var(--red);
}

.bw1,
.bw2,
.bw3,
.bw4 {
  --deprecated: 'WARNING EOL use "mb-border" instead';
  border-width: 2px;
}

/* deprecated vertical align of an inline or table-cell box */
.v-sub {
  --deprecated: 'WARNING EOL - no alternatives';
  vertical-align: sub;
}
.v-txt-top {
  --deprecated: 'WARNING EOL - no alternatives';
  vertical-align: text-top;
}
.v-txt-btm {
  --deprecated: 'WARNING EOL - no alternatives';
  vertical-align: text-bottom;
}
.v-mid {
  --deprecated: 'WARNING EOL - no alternatives';
  vertical-align: middle;
}
.v-top {
  --deprecated: 'WARNING EOL - no alternatives';
  vertical-align: top;
}
.v-btm {
  --deprecated: 'WARNING EOL - no alternatives';
  vertical-align: bottom;
}

/* deprecated floats */
.fl {
  --deprecated: 'WARNING EOL - no alternatives, maybe use flexbox instead';
  float: left;
}
.fr {
  --deprecated: 'WARNING EOL - no alternatives, maybe use flexbox instead';
  float: right;
}

/* deprecated float clearfix hacks */
.cf:before,
.cf:after {
  --deprecated: 'WARNING EOL - no alternatives';
  content: ' ';
  display: table;
}
.cf:after {
  --deprecated: 'WARNING EOL - no alternatives';
  clear: both;
}
.cf {
  --deprecated: 'WARNING EOL - no alternatives';
  *zoom: 1;
}
.cb {
  --deprecated: 'WARNING EOL - no alternatives';
  clear: both;
}

/* deprecated logo stuff */
.logo-brand--m {
  --deprecated: 'WARNING EOL - no alternatives';
  width: 150px;
  height: 60px;
}

/* Flexgrid */
.flexgrid {
  --deprecated: 'WARNING use "flex flex-wrap" instead';
  display: flex;
  flex-wrap: wrap;
}

.flexgrid__item {
  --deprecated: 'WARNING use "flex-none" instead';
  flex: 0 0 auto;
}

.flexgrid--gutters-s {
  --deprecated: 'WARNING EOL - no alternatives';
  margin: 0 -0.25rem 0 -0.25rem;
}

.flexgrid--gutters-s > .flexgrid__item {
  --deprecated: 'WARNING EOL - no alternatives';
  padding: 0 0.25rem 0.5rem 0.25rem;
}

.flexgrid--gutters-m {
  --deprecated: 'WARNING EOL - no alternatives';
  margin: 0 -0.5rem 0 -0.5rem;
}

.flexgrid--gutters-m > .flexgrid__item {
  --deprecated: 'WARNING EOL - no alternatives';
  padding: 0 0.5rem 1rem 0.5rem;
}

.flexgrid--gutters-l {
  --deprecated: 'WARNING EOL - no alternatives';
  margin: 0 -1rem 0 -1rem;
}

.flexgrid--gutters-l > .flexgrid__item {
  --deprecated: 'WARNING EOL - no alternatives';
  padding: 0 1rem 2rem 1rem;
}

.pagination__element,
.pagination__btn {
  --deprecated: 'EOL, unnecessary to use this class';
  min-width: 2em;
}

.pagination__btn--active {
  --deprecated: 'EOL, use aria-current="page" instead';
  border-bottom: 2px solid var(--green) !important;
  color: var(--green-dark) !important;
}

.pagination__btn--deactivated .icon-ui,
.pagination__btn--deactivated,
.pagination__btn--deactivated:hover,
.pagination__btn--deactivated:focus-visible {
  --deprecated: 'EOL - set aria-hidden="true" on the list item and remove the deactivated class';
  color: transparent;
  background: none;
}

.fw600 {
  --deprecated: 'Use "fwb" instead';
  font-weight: 600;
}

/* Local navigation */
.local-nav {
  --deprecated: 'Use contextual navigation instead';
  margin-bottom: 2rem;
  display: flex;
  flex-flow: row wrap;
}

.local-nav__item {
  --deprecated: 'Use contextual navigation instead';
  font-size: 1rem; /* fallback */
  font-size: clamp(0.9rem, calc(0.8rem + 0.2vw), 1.1rem);
  margin: 0;
  padding: 0;
  list-style: none;
}

.local-nav__link {
  --deprecated: 'Use contextual navigation instead';
  display: block;
  padding: 0.5rem 1rem 0.4rem 1rem;
  color: var(--gray-20);
  border-bottom: 2px solid var(--gray-86);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transition: var(--easeout-15);
  text-decoration: none;
}

.local-nav__link:hover,
.local-nav__link:focus-visible {
  background-color: var(--gray-95);
  border-color: var(--gray-66);
  color: var(--green-darker);
}

.local-nav__link--active {
  background-color: var(--green-lighter);
  color: var(--green-dark);
  border-color: var(--green);
}

.local-nav__link--active:hover,
.local-nav__link--active:focus-visible {
  background-color: var(--green-lighter-hover);
  color: var(--green-dark);
  border-color: var(--green-active);
}

/* Modal */
.mb-backdrop {
  --deprecated: 'Warning EOL - Use dialog element with mb-modal class instead';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: hsl(0 0% 0% / 0.6);
  padding: 1rem;
}

/* Deprecated active styling on mb-card */
input:checked + .mb-card,
.mb-card--active,
input:checked + .mb-card:hover,
.mb-card--active:hover,
.mb-card--active:focus-visible {
  --deprecated: 'Warning EOL - Use mb-tab instead of mb-card with mb-card--active';
  color: var(--gray-20);
  background-color: var(--green-lighter-active);
  border-bottom-color: var(--green-dark);
  box-shadow: none;
}
