@layer utilities_typography_font-size {
  .text-1\.5r {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  .text-1\.25r {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .text-1r {
    font-size: 1rem;
  }

  .text-0\.875r {
    font-size: 0.875rem;
  }
}
