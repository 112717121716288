/* Gap */
@layer utilities_flexbox_gap {
  .gaxs{ gap: .25rem; }
  .gas{ gap: .5rem; }
  .gam{ gap: 1rem; }
  .gal{ gap: 2rem; }
  .gaxl{ gap: 4rem; }

  .grxs{ row-gap: .25rem }
  .grs{ row-gap: .5rem }
  .grm{ row-gap: 1rem }
  .grl{ row-gap: 2rem }
  .grxl{ row-gap: 4rem }

  .gcxs{ column-gap: .25rem }
  .gcs{ column-gap: .5rem }
  .gcm{ column-gap: 1rem }
  .gcl{ column-gap: 2rem }
  .gcxl{ column-gap: 4rem }
}
