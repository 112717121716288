@layer components_lists {
  dl.mb-dlist {
    line-height: 1.2;
    font-size: clamp(0.875rem, 0.8rem + 0.15vw, 1rem);
    border-bottom: 2px solid var(--gray-80);
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1rem;

    dt {
      border-bottom: 1px solid var(--gray-80);
      padding: 0.6em 1em 0.6em 0;
      flex: 1 1 50%;
    }

    dd {
      border-bottom: 1px solid var(--gray-80);
      text-align: right;
      padding: 0.6em 0 0.6em;
      flex: 1 1 50%;
    }

    dd:last-of-type,
    dt:last-of-type {
      border-bottom: 0;
    }
  }

  dl.inline:has(> div) {
    gap: 0.25rem;

    > * {
      display: flex;
      gap: 0.25rem;
      margin: 0;

      dd {
        overflow-wrap: anywhere;
      }
    }
  }

  .list--none {
    list-style-type: none;
    margin-left: 0;
  }

  .list--bullets {
    list-style-type: disc;
    margin-left: 1em;
  }

  .list--numbers {
    list-style-type: decimal;
    margin-left: 1.6em;
  }
}
