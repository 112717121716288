/* Flex wrap */
@layer utilities_flexbox_wrap {
  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-nowrap {
    flex-wrap: nowrap;
  }
}
