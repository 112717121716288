@layer components_forms {
  .form__label {
    margin-bottom: 0.3em;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--gray-20);
    line-height: 1.35;
  }

  .form__label > :is(input, select, textarea) {
    margin-top: 0.3em;
    margin-bottom: 0.7rem;
    font-size: 1rem;
    font-weight: 400;
  }

  .form__control {
    min-height: 2.75rem; /* see explanation in main file */
    padding: 0.6em;
    line-height: 1.4;
    border: 1px solid var(--gray-55);
    border-radius: 2px;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    background: none;
    background-color: #fff;
    transition: var(--easeout-15);
  }

  textarea.form__control {
    min-height: 7rem;
  }
}

@layer components_forms_select {
  select.form__control {
    padding-top: 0.58em; /* lh works better, but not fully supported */
    padding: 0.45lh 0.6em; /* see explanation in main file */
  }

  .mb-rows-select {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-right: 0.5rem;

    label {
      margin-bottom: 0rem;
    }

    select.form__control {
      border-style: none;
      border-width: 0px;
      margin-bottom: 0rem;
    }

    select.form__control:hover {
      background-color: var(--gray-95);
    }
  }
}

@layer components_forms_validation {
  .form__control:not(.form__control--error, :disabled):hover,
  .form__control:not(.form__control--error, :disabled):focus-visible {
    border-color: var(--gray-20);
  }

  .form__control--error {
    border: 1px solid var(--red);
  }

  .form__label--error {
    color: var(--red);
    font-weight: 400;
    font-size: 0.875rem;
  }

  span.form__label--error::before {
    content: '\1F6AB\0020';
    font-size: 0.9em;
  }

  ul.form__label--error,
  ul:has(> li.form__label--error) {
    list-style: none;
    margin-left: 0;
  }

  ul.form__label--error li::before,
  li.form__label--error::before {
    content: '\1F6AB\0020';
    font-size: 0.9em;
  }

  .form__control:disabled {
    border: 1px solid var(--gray-80);
    background-color: var(--gray-98);
    color: var(--gray-55);
    cursor: not-allowed;
    opacity: 1; /* disabled selects have lower opacity in chrome */
  }

  :is(.form__label):has(
      input:disabled,
      select:disabled,
      + input:disabled,
      + select:disabled
    ) {
    cursor: not-allowed;
  }
}

@layer components_forms_check-radio {
  .form__check,
  .form__radio {
    display: inline-flex;
    align-items: baseline;
    margin-bottom: 0.3rem;
  }

  :is(.form__check, .form__radio) input {
    margin-right: 0.25rem;
    flex-shrink: 0;
  }

  :is(.form__check, .form__radio) input:disabled {
    cursor: not-allowed;
  }

  :is(.form__check, .form__radio):has(input:disabled) {
    cursor: not-allowed;
    color: hsl(0, 0%, 45%);
  }

  :is(.form__check, .form__radio):has(input:enabled):hover {
    color: #000;
  }

  /* target Safari */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .form__check input,
      .form__radio input {
        height: 0.87em;
      }
    }
  }
}
