@layer components_icons {
  .icon-service {
    width: 4rem;
    height: 4rem;
  }

  .mybring-service-icon--grey {
    fill: var(--gray-66);
  }

  .icon-ui {
    width: 1rem;
    height: 1rem;
    color: var(--gray-20);
    fill: currentColor;
  }

  .icon-ui--m {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--gray-20);
    fill: currentColor;
  }

  .icon-ui--l {
    width: 2rem;
    height: 2rem;
    color: var(--gray-20);
    fill: currentColor;
  }

  .icon-ui--green {
    color: var(--green);
    fill: currentColor;
  }

  .icon-ui--green-dark {
    color: var(--green-dark);
    fill: currentColor;
  }

  .icon-ui--orange {
    color: var(--yellow-dark);
    fill: currentColor;
  }

  .icon-ui--red {
    color: var(--red);
    fill: currentColor;
  }

  .icon-ui--gray {
    color: var(--gray-60);
    fill: currentColor;
  }

  .icon-ui--lightgray {
    color: var(--gray-80);
    fill: currentColor;
  }

  .icon-ui--white {
    color: #ffffff;
    fill: currentColor;
  }

  [data-serviceicon],
  [data-serviceicon-loaded],
  [data-mybicon],
  [data-mybicon-loaded] {
    display: inline-block;
    display: contents;
  }
}