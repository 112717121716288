@layer utilities_flexbox_justify {
  /* Justify Content */
  .justify-cfs {
    justify-content: flex-start;
  }

  .justify-cfe {
    justify-content: flex-end;
  }

  .justify-cc {
    justify-content: center;
  }

  .justify-csb {
    justify-content: space-between;
  }

  .justify-csa {
    justify-content: space-around;
  }

  .justify-cse {
    justify-content: space-evenly;
  }
}

@layer utilities_flex_align {
  /* Align Items */
  .align-ic {
    align-items: center;
  }

  .align-ib {
    align-items: baseline;
  }

  .align-ifs {
    align-items: flex-start;
  }

  .align-ife {
    align-items: flex-end;
  }

  .align-is {
    align-items: stretch;
  }

  /* Align Content */

  .align-cfs {
    align-content: flex-start;
  }

  .align-cfe {
    align-content: flex-end;
  }

  .align-cc {
    align-content: center;
  }

  .align-csa {
    align-content: space-around;
  }

  .align-csb {
    align-content: space-between;
  }

  .align-cse {
    align-content: space-evenly;
  }

  .align-cs {
    align-content: stretch;
  }

  /* Align Self */

  .align-sc {
    align-self: center;
  }

  .align-sfe {
    align-self: flex-end;
  }

  .align-sfs {
    align-self: flex-start;
  }

  .align-ss {
    align-self: stretch;
  }

  .align-sb {
    align-self: baseline;
  }
}