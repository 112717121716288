/* Nested css for Mybring documentation */
.mb-docs {
  & h1:not(.exa) {
    font-size: clamp(2rem, calc(1.75rem + 1vw), 2.75rem);
  }

  :is(
      blockquote,
      div,
      table,
      ol,
      ul,
      code,
      .highlight,
      pre,
      p,
      details,
      figure,
      img
    ) {
    + h2,
    + h3,
    + h4,
    + h5 {
      margin-top: 2em;
    }
  }

  & ul {
    list-style-type: disc;
    margin-left: 1em;
  }

  & ul ul {
    list-style-type: circle;
    margin-bottom: 0;
  }

  & ol {
    list-style-type: decimal;
    margin-left: 1.6em;
  }

  /* Additions and mods to tables.css */
  table {
    text-align: left;
    overflow-x: auto;
    margin-bottom: 2rem;

    &:has(caption) {
      margin-top: 2rem;
    }

    tbody code {
      background: transparent;
      color: hsl(155, 100%, 19.6%);
      padding: 0;
    }

    td > p,
    td > ul {
      margin-bottom: 0;
    }
  }
}
