/*
    POINTER EVENTS
*/
@layer {
  /* Keyword values */
  .pointev-auto {
    pointer-events: auto;
  }
  .pointev-none {
    pointer-events: none;
  }

  /* Global values */
  .pointev-inherit {
    pointer-events: inherit;
  }
  .pointev-initial {
    pointer-events: initial;
  }
  .pointev-revert {
    pointer-events: revert;
  }
  .pointev-unset {
    pointer-events: unset;
  }
  
  .cpointer:not(button, summary, details, th) {
    cursor: pointer;
  }
}