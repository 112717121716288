@layer components_modal {
  .mb-modal {
    border-radius: 2px;
    background: white;
    padding: 0;
    position: fixed;
    z-index: 1010;
    box-shadow: var(--shadow-dark);
    line-height: 1.3;
    border:none;
  }

  .mb-modal > div {
    padding: 2rem 2rem 1rem;
    &.mb-carousel-container {
      padding: 3rem 3.5rem 2rem 3.5rem;
    }
    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .mb-modal::backdrop {
    background-color: hsl(0 0% 0% / 0.6);
  }

  .mb-modal--danger > div {
    border-top: 2px solid var(--red);
    border-bottom: 2px solid var(--red);
    border-radius: 2px;
  }

  /* Content carousel in modal */
  .mb-carousel {
    position: relative;
    .carousel-slider {
      display: flex;
      overflow: clip;
      position: relative;
      & > * {
        min-width: 100%;
        transition: all 0.2s ease-in-out;
      }
    }
    .arrow-button {
      position: absolute;
      top: calc(50% - 1.25rem);
      transform: translateY(calc(-50%));
      aspect-ratio: 1/1;
      margin: 0;
      &.left-btn {
        left: -3rem;
      }
      &.right-btn {
        right: -3rem;
      }
    }
    .carousel-dot {
      background-color: var(--gray-90);
      border: none;
      height: 1.22rem;
      width: 1.22rem;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;
      transition: var(--easeout-15);
      &:hover,
      &:focus-visible {
        background-color: var(--gray-80);
      }
      span {
        width: 100%;
        height: 100%;
        display: block;
        &.active {
          background-color: var(--green);
        }
      }
    }
  }
  /* END */
}