/*
    p = padding (spacing on the inside of the box)
    m = margin (spacing on the outside of the box)

    DIRECTION
    a = all
    h = horizontal
    v = vertical
    t = top
    r = right
    b = bottom
    l = left

    SCALE IN T-SHIRT SIZES
    0 / xs / s / m / l / xl

*/
@layer utilities_size-and-space_padding {
  .pa0 {
    padding: 0;
  }
  .paxs {
    padding: 0.25rem;
  }
  .pas {
    padding: 0.5rem;
  }
  .pam {
    padding: 1rem;
  }
  .pal {
    padding: 2rem;
  }
  .paxl {
    padding: 4rem;
  }
  .ph0 {
    padding-left: 0;
    padding-right: 0;
  }
  .phxs {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .phs {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .phm {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .phl {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .phxl {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pvxs {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .pvs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pvm {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .pvl {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .pvxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .pl0 {
    padding-left: 0;
  }
  .plxs {
    padding-left: 0.25rem;
  }
  .pls {
    padding-left: 0.5rem;
  }
  .plm {
    padding-left: 1rem;
  }
  .pll {
    padding-left: 2rem;
  }
  .plxl {
    padding-left: 4rem;
  }
  .pr0 {
    padding-right: 0;
  }
  .prxs {
    padding-right: 0.25rem;
  }
  .prs {
    padding-right: 0.5rem;
  }
  .prm {
    padding-right: 1rem;
  }
  .prl {
    padding-right: 2rem;
  }
  .prxl {
    padding-right: 4rem;
  }
  .pb0 {
    padding-bottom: 0;
  }
  .pbxs {
    padding-bottom: 0.25rem;
  }
  .pbs {
    padding-bottom: 0.5rem;
  }
  .pbm {
    padding-bottom: 1rem;
  }
  .pbl {
    padding-bottom: 2rem;
  }
  .pbxl {
    padding-bottom: 4rem;
  }
  .pt0 {
    padding-top: 0;
  }
  .ptxs {
    padding-top: 0.25rem;
  }
  .pts {
    padding-top: 0.5rem;
  }
  .ptm {
    padding-top: 1rem;
  }
  .ptl {
    padding-top: 2rem;
  }
  .ptxl {
    padding-top: 4rem;
  }
}

@layer utilities_size-and-space_margin {
  .ma0 {
    margin: 0;
  }
  .maxs {
    margin: 0.25rem;
  }
  .mas {
    margin: 0.5rem;
  }
  .mam {
    margin: 1rem;
  }
  .mal {
    margin: 2rem;
  }
  .maxl {
    margin: 4rem;
  }
  .mh0 {
    margin-left: 0;
    margin-right: 0;
  }
  .mhxs {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .mhs {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mhm {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mhl {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mhxl {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mvxs {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .mvs {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .mvm {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mvl {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mvxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .ml0 {
    margin-left: 0;
  }
  .mlxs {
    margin-left: 0.25rem;
  }
  .mls {
    margin-left: 0.5rem;
  }
  .mlm {
    margin-left: 1rem;
  }
  .mll {
    margin-left: 2rem;
  }
  .mlxl {
    margin-left: 4rem;
  }
  .mr0 {
    margin-right: 0;
  }
  .mrxs {
    margin-right: 0.25rem;
  }
  .mrs {
    margin-right: 0.5rem;
  }
  .mrm {
    margin-right: 1rem;
  }
  .mrl {
    margin-right: 2rem;
  }
  .mrxl {
    margin-right: 4rem;
  }
  .mb0 {
    margin-bottom: 0;
  }
  .mbxs {
    margin-bottom: 0.25rem;
  }
  .mbs {
    margin-bottom: 0.5rem;
  }
  .mbm {
    margin-bottom: 1rem;
  }
  .mbl {
    margin-bottom: 2rem;
  }
  .mbxl {
    margin-bottom: 4rem;
  }
  .mt0 {
    margin-top: 0;
  }
  .mtxs {
    margin-top: 0.25rem;
  }
  .mts {
    margin-top: 0.5rem;
  }
  .mtm {
    margin-top: 1rem;
  }
  .mtl {
    margin-top: 2rem;
  }
  .mtxl {
    margin-top: 4rem;
  }

  /* magic class to center an element */
  .m-auto {
    margin-left: auto;
    margin-right: auto;
  }

  /* …or push all the way in one direction */
  .mt-auto {
    margin-top: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .mb-auto {
    margin-bottom: auto;
  }
  .ml-auto {
    margin-left: auto;
  }
}

/* owl classes to space child elements */
@layer utilities_size-and-space_owl {
  .owlxs > * + * {
    margin-top: 0.25rem;
  }

  .owls > * + * {
    margin-top: 0.5rem;
  }

  .owlm > * + * {
    margin-top: 1rem;
  }

  .owll > * + * {
    margin-top: 2rem;
  }

  .owlxl > * + * {
    margin-top: 4rem;
  }
}
