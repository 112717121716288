@layer components_forms {
  .mb-datepicker__wrapper {
    width: 9rem;
    position: relative;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .mb-datepicker-popper {
    z-index: 2 !important;

    .react-datepicker__triangle {
      stroke: var(--gray-80) !important;
    }
  }

  .mb-datepicker.react-datepicker,
  .mb-timepicker.react-datepicker {
    font-size: 0.9rem;
    border-radius: 2px;
    font-family: var(--font-family);
    box-shadow: var(--shadow-dark);
    border: 1px solid var(--gray-80);
  }

  .mb-datepicker__icon {
    z-index: 1;
    position: absolute;
    top: 0.5em;
    right: 0.75em;
    pointer-events: none;
  }

  .mb-datepicker {
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation ::before {
      border-color: var(--gray-40);
    }

    .react-datepicker__navigation:hover ::before,
    .react-datepicker__navigation:focus-visible ::before {
      border-color: var(--gray-20);
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
      border: 1px solid var(--gray-80);
      border-radius: 2px;
      text-transform: capitalize;
    }

    .react-datepicker__header {
      padding-top: 4px;
      background-color: var(--gray-95);
      border: 0;
      border-radius: 0;
      text-transform: capitalize;
    }

    .react-datepicker__day:not([aria-disabled='true']):hover,
    .react-datepicker__month-text:not([aria-disabled='true']):hover,
    .react-datepicker__quarter-text:not([aria-disabled='true']):hover,
    .react-datepicker__year-text:not([aria-disabled='true']):hover {
      background-color: var(--gray-90);
      border-radius: 2px;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: var(--green-light) !important;
      border-radius: 2px !important;
      font-weight: 700;
      color: initial;
    }

    .react-datepicker__week-number {
      color: var(--gray-40);
    }

    .react-datepicker__week-number--keyboard-selected {
      background-color: var(--green-dark);
      border-radius: 2px;
    }

    .react-datepicker__day--disabled:hover {
      background: none;
    }

    .react-datepicker__current-month {
      margin-top: 4px;
      margin-bottom: 12px;
    }
  }

  .mb-datepicker .react-datepicker__current-month,
  .mb-timepicker .react-datepicker-time__header {
    font-size: 0.9rem;
  }

  .mb-timepicker
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: auto;
    padding: 10px;
  }
}
