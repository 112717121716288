@layer components_buttons {
  .btn {
    min-height: 2.75rem; /* see explanation in main file */
    padding: 0.75em 1em;
    line-height: 1.25;
    border: 1px solid var(--gray-75);
    border-radius: 2px;
    font-size: 0.95rem;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.25em;
    justify-content: center;
    margin-bottom: 1rem;
    appearance: none;
    transition: var(--easeout-15);
    width: fit-content;

    &:has(.icon-ui--m) {
      padding: 0.59em 0.6em;
    }

    > svg {
      flex: 0 0 1em;
    }
  }

  .btn,
  .btn:link,
  .btn:visited {
    background-color: var(--gray-95);
    color: black;
  }

  .btn:hover {
    background-color: var(--gray-90);
  }

  .btn:active,
  .btn.active {
    background-color: var(--gray-80);
  }

  .btn:disabled {
    background-color: var(--gray-93);
    border: 1px solid var(--gray-86);
    color: var(--gray-55);
    fill: currentColor;
    cursor: not-allowed;
  }

  .btn--green,
  .btn--green:link,
  .btn--green:visited {
    border: 1px solid hsl(101, 68%, 34%);
    background-color: var(--green);
  }

  .btn--green:hover,
  .btn--green:focus-visible {
    border: 1px solid hsl(101, 68%, 34%);
    background-color: var(--green-hover);
  }

  .btn--green:active,
  .btn--green.active {
    border: 1px solid hsl(101, 68%, 34%);
    background-color: var(--green-active);
  }

  .btn--green:disabled {
    border: 1px solid hsl(86, 34%, 85%);;
    background-color: var(--green-lighter);
  }

  .btn--white,
  .btn--white:link,
  .btn--white:visited {
    background-color: white;
  }

  .btn--white:hover,
  .btn--white:focus-visible {
    background-color: var(--gray-95);
  }

  .btn--white:active,
  .btn--white.active {
    background-color: var(--gray-90);
  }

  .btn--danger,
  .btn--danger:link,
  .btn--danger:visited {
    background-color: var(--gray-95);
    border: 1px solid var(--gray-75);
    color: var(--red-dark);
  }

  .btn--danger .icon-ui {
    color: var(--red-dark);
  }

  .btn--danger:hover,
  .btn--danger:focus-visible {
    background-color: hsl(3, 77.5%, 90%);
    border: 1px solid var(--red-light);
  }

  .btn--danger:active,
  .btn--danger.active {
    background-color: hsl(3, 77.5%, 86%);
    border: 1px solid var(--red-light);
  }
}

@layer components_buttons_toolbar {
  .btngroup {
    display: flex;
    flex-flow: row wrap;
    column-gap: 0.25rem;
    margin-bottom: 0.75rem;

    > .btn,
    > .btn-link,
    > .btn-link--dark {
      margin-bottom: 0.25rem;
    }
  }
}

@layer components_buttons_mini-buttons {
  .btn-link,
  .btn-link--dark {
    display: flex;
    padding: 0.4em;
    border: 0;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    white-space: nowrap;
    color: var(--gray-20);
    width: max-content;
    height: max-content;
    text-decoration: none;
    transition: var(--easeout-15);
    line-height: 1;
  }

  .btn-link,
  .btn-link:disabled:hover {
    background-color: hsla(0, 0%, 0%, 0);
  }

  .btn-link:hover,
  .btn-link:focus-visible,
  .btn-link--dark,
  .btn-link--dark:disabled:hover {
    color: var(--gray-20);
    background-color: hsla(0, 0%, 0%, 0.08);
    text-decoration: none;
  }

  .btn-link--dark:hover,
  .btn-link--dark:focus-visible {
    background-color: hsla(0, 0%, 0%, 0.14);
    text-decoration: none;
    color: var(--gray-20);
  }

  .btn-link:active,
  .btn-link.active,
  .btn-link[aria-selected="true"] {
    background-color: hsla(0, 0%, 0%, 0.12);
  }

  .btn-link--dark:active,
  .btn-link--dark.active,
  .btn-link--dark[aria-selected="true"] {
    background-color: hsla(0, 0%, 0%, 0.18);
  }

  .btn-link:disabled,
  .btn-link--dark:disabled,
  .btn-link--dark:disabled:hover {
    color: hsla(0, 0%, 0%, 0.53);
    cursor: not-allowed;
  }

  .btn-link:disabled svg,
  .btn-link:disabled:hover svg,
  .btn-link--dark:disabled svg,
  .btn-link--dark:disabled:hover svg {
    fill: hsla(0, 0%, 0%, 0.4);
  }

  .btn-link--help {
    background: none;
    padding: 0;
  }

  .btn-link--help:hover,
  .btn-link--help:focus-visible {
    background: none;
  }

  .btn-link--help svg {
    fill: currentColor;
    color: var(--gray-20);
  }

  .btn-link--help:hover svg,
  .btn-link--help:focus-visible svg {
    color: var(--gray-55);
  }

  table .btn-link-padding {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
  }
}
