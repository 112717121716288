/* Text colors */
@layer utilities_typography_text-color {
  .base {
    color: var(--gray-20);
  }
  .white {
    color: #fff;
  }
  .error {
    color: var(--red);
  }
  .green-dark {
    color: var(--green-dark);
  }
}

/* Background colors */
@layer utilities_style_backgrounds {
  .bg-gray2 {
    background-color: var(--gray-95);
  }

  .bg-white {
    background-color: #fff;
  }
}
