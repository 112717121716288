@layer components_links {
  .link-white {
    color: #fff;
    text-decoration-color: var(--gray-80);
  }

  .link-white:hover,
  .link-white:focus-visible {
    color: var(--gray-95);
    text-decoration-color: var(--gray-60);
  }
}
