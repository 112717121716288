/*
    POSITION
    to change the default position (use sparingly!)
*/
@layer utilities_position {
  .static { position: static; }
  .relative { position: relative; }
  .absolute { position: absolute; }
  .sticky { position: sticky; }
  .fixed { position: fixed; }
}
