@layer components_disclosure {
  .mb-disclosure {
    border-radius: 2px;
  }

  .mb-disclosure > summary {
    padding: 0.675rem 0.6rem;  /* see explanation in main file */
    border-radius: 2px;
    transition: background-color var(--easeout-15);
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0.25em;
  }

  .mb-disclosure > summary:where(:hover, :focus-visible) {
    background-color: var(--gray-93);
  }

  .mb-disclosure[open] > summary {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* disclosure arrow */
  .mb-disclosure > summary::marker,
  .mb-disclosure > summary::-webkit-details-marker {
    display: none;
  }

  .mb-disclosure > summary svg {
    width: 1rem;
    transition: var(--easeout-15);
    fill: currentColor;
    height: auto;
    flex: 0 0 1rem;
  }

  .mb-disclosure[open] > summary svg {
    transform: rotate(90deg);
  }

  /* bg-gray1 */
  .mb-disclosure.bg-gray1 > summary {
    background-color: var(--gray-93);
  }

  .mb-disclosure.bg-gray1 > summary:where(:hover, :focus-visible) {
    background-color: var(--gray-90);
  }

  /* bg-green1 */
  .mb-disclosure.bg-green1 > summary:where(:hover, :focus-visible),
  .mb-disclosure.bg-green1[open] > summary {
    background-color: var(--green-lighter-hover);
  }

  .mb-disclosure.bg-green1[open] > summary:where(:hover, :focus-visible) {
    background-color: var(--green-lighter-active);
  }

  /* alert messages */
  :is(.mb-disclosure.message--warn) > summary {
    padding: 0.75rem;
  }

  .mb-disclosure.message--warn > summary:where(:hover, :focus-visible) {
    background-color: hsl(48, 98%, 87%);
  }
}
