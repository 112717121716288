@layer utilities_typography_font-style {
  /* Font weight */

  .fwn {
    font-weight: 400;
  }
  .fwb {
    font-weight: 600;
  }

  /* Font style */

  .fsn {
    font-style: normal;
  }
}

/* Measure */

.measure {
  max-width: 34em;
} /* ~80 characters line length */

@layer utilities_typography_line-height {
  /* Line height / leading */

  .lh-solid {
    line-height: 1;
  }
  .lh-tight {
    line-height: 1.3;
  }
}

@layer utilities_typography_text-align {
  /* Text align */

  .tl {
    text-align: left;
  }
  .tr {
    text-align: right;
  }
  .tc {
    text-align: center;
  }
}

@layer utilities_typography_text-transform {
  /* Text transform */

  .ttc {
    text-transform: capitalize;
  }
  .ttl {
    text-transform: lowercase;
  }
  .ttu {
    text-transform: uppercase;
  }
  .ttn {
    text-transform: none;
  }
}

@layer utilities_typography_text-wrapping {
  /* Text wrapping */

  .ws-nowrap {
    white-space: nowrap;
  }
  .ofw-anywhere {
    overflow-wrap: anywhere;
  }
  .wb-break-all {
    word-break: break-all;
  }
}
