@layer components_cards {
  .mb-card {
    display: block;
    color: hsla(0, 0%, 0%, 0.75);
    background-color: var(--green-lighter);
    border: 0;
    border-bottom: 2px solid var(--green-lighter);
    border-radius: 2px;
    transition: var(--easeout-15), outline 0s;
    text-decoration: none;
  }

  .mb-card:hover,
  .mb-card:focus-visible {
    color: var(--gray-20);
    background-color: var(--green-lighter-hover);
    border-bottom-color: var(--green-lighter-hover);
    box-shadow: var(--shadow-dark);
  }

  input:focus-visible + label.mb-card {
    outline: 2px solid var(--green-dark);
  }
}
