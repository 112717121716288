@layer components_tabs {
  .mb-tab {
    color: hsla(0, 0%, 0%, 0.75);
    border: 0;
    border-radius: 2px;
    transition: var(--easeout-15), outline 0s;
    text-decoration: none;
    background-color: #fff;
    box-shadow: var(--shadow);
    border-bottom: 2px solid #fff;
  }

  .mb-tab:hover,
  .mb-tab:focus-visible {
    color: var(--gray-20);
    background-color: var(--gray-93);
    border-bottom: 2px solid var(--gray-93);
    box-shadow: var(--shadow);
  }

  .mb-tab[aria-selected='true'] {
    border-bottom: 2px solid var(--green-active);
  }
}