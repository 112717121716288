@layer components_forms {
  .searchable-combobox {
    ul:has(ul) > li {
      margin-bottom: 1rem;

      > div {
        border-top: 1px solid var(--gray-80);
        font-weight: 600;
        margin: 0 0.75rem 0;
        padding-top: 1rem;
      }

      &:first-of-type > div {
        border: none;
      }
    }

    .searchable-combobox__results {
      width: 100%;
      position: absolute;
      box-shadow: var(--shadow);
      z-index: 900;
      background-color: var(--gray-98);
      border: 1px solid var(--gray-75);
      border-top: none;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      overflow-y: auto;
      max-height: 50vh;
    }

    .searchable-combobox__results--item_list, /* deprecated */
    .searchable-combobox__list {
      li[role='option'] {
        display: flex;
        padding: 0.25rem 0.75rem;

        &:not([aria-selected='true']) {
          &.searchable-combobox__results--focused_item, /* deprecated */
          &.searchable-combobox__item--focus,
          &:hover {
            background-color: hsla(96, 23%, 74%, 0.3);
            outline: 1px solid hsl(155, 50%, 19.6%, 0.3);
            outline-offset: -2px;
          }
        }

        > div {
          margin-left: 1.5rem;
        }
      }

      li[aria-selected='true'] {
        background-color: hsla(96, 53%, 74%, 0.6);

        > div {
          margin-left: 0.5rem;
        }

        svg {
          width: 0.9rem;
          height: 0.9rem;
          margin-top: 0.25rem;
          margin-left: 0.2rem;
          fill: var(--green-dark);
        }
      }
    }

    .select-arrow {
      right: 0.6rem;
    }
  }
}
