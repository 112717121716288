@layer components_forms_file-upload {
  .fileupload__header {
    border-color: var(--green-dark);
    border-style: dashed;
    border-radius: 2px;
    transition: var(--easeout-15), border 0s;
  }

  .fileupload__header:hover,
  .fileupload--accept {
    background-color: var(--green-lighter);
  }

  div:has(.fileupload__header):focus-visible > .fileupload__header {
    background-color: var(--green-lighter);
    border-style: solid;
  }

  .fileupload--reject,
  div:has(.fileupload__header):focus-visible > .fileupload--reject {
    background-color: var(--red-lighter);
    border-color: var(--red-dark);
  }
}