/* Flex direction */
@layer utilities_flexbox_direction {
  .flex-dir-col {
    flex-direction: column;
  }

  .flex-dir-col-rev {
    flex-direction: column-reverse;
  }

  .flex-dir-row {
    flex-direction: row;
  }

  .flex-dir-row-rev {
    flex-direction: row-reverse;
  }
}
