@layer utilities_style_shadow {
  .bshadow {
    box-shadow: var(--shadow)
  }

  .bshadow-dark,
  .bshadow--dark {
    box-shadow: var(--shadow-dark);
  }
}
