@layer components_spinner {
  .spinner {
    display: inline-block;
    position: relative;
    margin: 0px auto;
    width: 1rem;
    height: 1rem;
  }

  .spinner--l {
    display: inline-block;
    position: relative;
    margin: 0px auto;
    width: 2rem;
    height: 2rem;
  }

  .spinner--xl {
    display: inline-block;
    position: relative;
    margin: 0px auto;
    width: 4rem;
    height: 4rem;
  }

  .spinner:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .spinner__circular {
    animation: rotate 1.1s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .spinner__path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 2.2s ease-in-out infinite;
    stroke-linecap: round;
    stroke: var(--gray-20);
    stroke-width: 4;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
